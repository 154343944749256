import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <div className="footer-container1">
          <span className="footer-text">{props.Brand1}</span>
          <span className="">{props.CopyRight}</span>
        </div>
        <div className="footer-container2">
          <span className="footer-text02">{props.ContactMe}</span>
          <a
            href="mailto:ta@projectstart.app?subject=Support"
            className="footer-link"
          >
            {props.emailAdress}
          </a>
        </div>
        <div className="footer-container3">
          <div className="footer-container4">
            <span className="footer-text03 Large">Pages</span>
            <span className="footer-text04 Large">Wizard</span>
            <span className="footer-text05">
              <span className="Large footer-text06">Support me</span>
              <br className=""></br>
            </span>
            <span className="footer-text08 Large">About</span>
          </div>
          <div className="footer-container5">
            <span className="footer-text09 Large">Products</span>
            <span className="footer-text10">Wizard</span>
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/playground_assets/waves-white.svg"
        className="footer-image"
      />
    </footer>
  )
}

Footer.defaultProps = {
  emailAdress: 'ta@projectstart.app',
  ContactMe: 'Contact me',
  text1: 'HELLO WORLD APP',
  CopyRight: 'Copyright © 2022 Project Start App',
  CopyRight1: 'Copyright © 2022 Project Start App',
  Brand1: 'Project Start App',
  text: 'Copyright © 2022 Project Start App',
  rootClassName: '',
}

Footer.propTypes = {
  emailAdress: PropTypes.string,
  ContactMe: PropTypes.string,
  text1: PropTypes.string,
  CopyRight: PropTypes.string,
  CopyRight1: PropTypes.string,
  Brand1: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer
