import React from 'react'

import PropTypes from 'prop-types'

import './outline-gray-button.css'

const OutlineGrayButton = (props) => {
  return (
    <div
      className={`outline-gray-button-container button ${props.rootClassName} `}
    >
      <div className="outline-gray-button-container1">
        <button className="outline-gray-button-button ButtonSmall">
          {props.button}
        </button>
        <svg
          viewBox="0 0 877.7142857142857 1024"
          className="outline-gray-button-icon"
        >
          <path
            d="M676.571 512c0 13.143-6.857 25.143-18.286 31.429l-310.857 182.857c-5.714 3.429-12 5.143-18.286 5.143s-12.571-1.714-18.286-4.571c-11.429-6.857-18.286-18.857-18.286-32v-365.714c0-13.143 6.857-25.143 18.286-32 11.429-6.286 25.714-6.286 36.571 0.571l310.857 182.857c11.429 6.286 18.286 18.286 18.286 31.429zM749.714 512c0-171.429-139.429-310.857-310.857-310.857s-310.857 139.429-310.857 310.857 139.429 310.857 310.857 310.857 310.857-139.429 310.857-310.857zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
            className=""
          ></path>
        </svg>
      </div>
    </div>
  )
}

OutlineGrayButton.defaultProps = {
  button: 'Button',
  rootClassName: '',
}

OutlineGrayButton.propTypes = {
  button: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default OutlineGrayButton
